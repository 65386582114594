import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUserToVideoRoom, videoOdasiDilimi, videoodasi } from '../VideoOdasi/videoOdasiDilimi'

import './demo.css' 
import { soketSunucusunaBaglan } from '../soketBaglantisi/soketBaglantisi'

function Demo() {
    /*// aşağıdaki kısmı sonradan çıkart
    const odadakiKullanicilar = useSelector(state => state.cevrimIciKullanicilar)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(addUserToVideoRoom("o kişi"))
    })
    useEffect(() => {
        soketSunucusunaBaglan()
    // })
    }, [odadakiKullanicilar])

    // yukarıdaki kısmı sonradan çıkart */
    return (
        <div className='demoDivCls'>
            <h1>YAPIM HALİNDE - UNDER CONSTRUCTION - В разработке! - Bajo construcción</h1>
            <h1>LÜTFEN KAYIT OLMAYIN - PLEASE DO NOT REGISTER - Не регистрируйтесь! - No te registres</h1>
        </div>
    )
}

export default Demo