import React, { useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';

import './cookieConsent.css'

function CookieConsent() {
    const { t } = useTranslation();
    const [ cookies, setCookie] = useCookies(['IAcceptAllCookies']);

    const tmpHideDiv = useRef()

    const cereziYediGuneAyarla = () => {
        setCookie("IAcceptAllCookies", "yesIAcceptAllCookies", {
            path: "/",
            maxAge: 60 * 60 * 24 * 7, //7 gün
            sameSite: true,
            hostOnly: false,
        })
    }

    const cerezleriKabulEt = () => {
        cereziYediGuneAyarla()
    };

    const cerezleriReddet = () => {
        setCookie("IAcceptAllCookies", "", {
            path: "/",
            sameSite: true,
            hostOnly: false,
        })
        tmpHideDiv.current.className = "hideItCls"
    };

    // var classToBeAddedToDiv = "";

    /*useEffect(() => {
        return () => {
            cookiesAccepted = cookies.IAcceptAllCookies;
            classToBeAddedToDiv = "";
            cookiePromptRef.current.className = "";
            if(cookiesAccepted==='yesIAcceptAllCookies'){
                classToBeAddedToDiv = "hideItCls"
                cereziYediGuneAyarla()
            }
            else {
                classToBeAddedToDiv = ""
            }
            if(classToBeAddedToDiv.length >0){
                cookiePromptRef.current.className = classToBeAddedToDiv;
            }
            console.log("cookiesAccepted changed: cookiesAccepted", cookiesAccepted)
        }
    }, [cookiesAccepted]);
    
    useEffect(() => {
        return () => {
            cookiesAccepted = cookies.IAcceptAllCookies;
            console.log("CookieConsent comp Mounted: cookiesAccepted", cookiesAccepted)
            if(cookiesAccepted === '' || cookiesAccepted === undefined){
                setCookie("IAcceptAllCookies", "", {
                    path: "/",
                    sameSite: true,
                    hostOnly: false,
                })
            }
        }
    }, []);*/
    
    return (
        <div ref={tmpHideDiv}
        id="myCookieConsent">
            <div>{t("footerStrings:cookiewarningS")} 
            <a href={t("footerStrings:privacyLink")}>{t("footerStrings:privacyText")}</a></div>
            <button className='greenBackgroundCls paddingSides5Rem marginSides5Rem' onClick={cerezleriKabulEt}>{t("footerStrings:iAgreeS")}</button>
            <button className='redBackgroundCls paddingSides5Rem marginSides5Rem' onClick={cerezleriReddet}>{t("footerStrings:iRejectS")}</button>
        </div>
    )
}

export default CookieConsent