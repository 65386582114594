import React, { useEffect, useState } from 'react'
import { motion, useAnimate } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import './AnimasyonluKarakterler.css'
// import i18n from '../i18n';


const AnimasyonluKarakterler = () =>{
    const { t } = useTranslation();
    // const lang = i18n.language
    // const [ spans, setSpans] = useState([])
    const [ karakterKapsami, animate ] = useAnimate();
    let karakterler = t('messages:passwordMinLengthS:info', {returnObjects: true})
    // console.log(karakterler)
    let spanlar = []
    // setSpans(() => {
        spanlar = karakterler.map((ele, diz) => {
            return (
            <motion.span 
            key={diz}
            // className='redSpanCls'
            id={`span${diz}`}
            aria-label={ele.description}
            >
            {ele.symbol}
            </motion.span>)
        })
    // })

    /*useEffect(() => {
        const getSpanlar = () => {
            console.log("dil değişti lang: ", lang)
            karakterler = t('messages:passwordMinLengthS:info', {returnObjects: true})
            setSpans(() => {
                karakterler.map((ele, diz) => {
                    return (
                    <motion.span 
                    key={diz}
                    id={`span${diz}`}
                    aria-label={ele.description}
                    >
                    {ele.symbol}
                    </motion.span>)
                })
            })
            // return spanlar = karakterler.map((ele, diz) => {
            //     return (
            //     <motion.span 
            //     key={diz}
            //     id={`span${diz}`}
            //     aria-label={ele.description}
            //     >
            //     {ele.symbol}
            //     </motion.span>)
            // })
        }
        getSpanlar()
        console.log("spans", spans)
        spanlar = spans
        console.log("spanlar", spanlar)
    }, [lang])*/

    useEffect(() => {
        const animateSpans = async () =>{
            await animate([
                ["#span0", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span1", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span2", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span3", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span4", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span5", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
                ["#span6", 
                {scale:[1, 3.5, 1]}, 
                {duration: 1}],
            ],
            {repeat:Infinity})
        }
        animateSpans();
    }, [])

    return (
        <div 
        className='relativePosCls'
        ref={karakterKapsami}
        >
        {spanlar}
        </div>
    )
}

export default AnimasyonluKarakterler