import React from 'react'

import './mobileapps.css'

const MobileApps = () => {
    return (
        <div>
            <div className='mobileAppItem'>
                <a href='https://play.google.com/store/apps/developer?id=ATB-SOFT'
                target='_blank'
                rel='noopener noreferrer'>Android Apps
                </a>
            </div>
            <div className='mobileAppItem'>
                <a href='https://apps.apple.com/us/developer/atb-soft/id1642503579'
                target='_blank'
                rel='noopener noreferrer'>iPhone Apps
                </a>
            </div>
        </div>
    )
}

export default MobileApps