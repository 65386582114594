import React from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici'

import Navbar from '../Bilesenler/Navbar'
import Footer from '../Bilesenler/Footer'
import Demo from '../Bilesenler/Demo'
import EkmekKirintilari from '../Bilesenler/EkmekKirintilari'

function KokYerlesim() {
    const { demoState} = useGenelAyarlarBaglami();
    return (
        <div id="mainBodyId" className='kokYerlesim'>
            <Navbar/>
            <main className='homePage'>
                <section className='mainSection'>
                    <EkmekKirintilari/>
                    {demoState && <Demo/>}
                    <ErrorBoundary>
                        <Outlet/>
                    </ErrorBoundary>
                    {/* <SignUp/> */}
                </section>
            </main>
            <Toaster position="top-center" />
            <Footer/>
        </div>
    )
}

export default KokYerlesim