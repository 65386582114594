import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
    <div>
    NotFound
    <Link to={"/"}>Home Page</Link>
    </div>
    )
}

export default NotFound