import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import AnimasyonluKarakterler from './AnimasyonluKarakterler';
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils';

export let API_URLSI = "http://localhost:3500"

const Signin = () => {
    const { t, i18n } = useTranslation(); 
    const aciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const {
        register, 
        handleSubmit, 
        // watch,
        setError, 
        formState: 
        {errors, isSubmitting} 
    } = useForm({
        reValidateMode: 'onChange'
    })

    // const [email, setEmail] = useState('');
    // const [sifre, setSifre] = useState('');
    // const [hataMesaji, setHataMesaji] = useState('');

    useEffect(() => {
        // emailRef.current.focus();
        API_URLSI = window.location.protocol+ window.location.host
        // console.log("window.location.protocol: ", window.location.protocol)
        // console.log("window.location.host: ", window.location.host)
        // console.log("API_URLSI: ", API_URLSI)
    }, [])

    const submitSignIn = async (data) => {
        // const submitSignUp = (e) => {
        //     e.preventDefault();
        // console.log(data)
        // console.log("JSON.stringify(data): ", JSON.stringify(data))
        try {
            // setHataMesaji('')
            console.log("fetch öncesi API_URLSI: ", API_URLSI)
            console.log("BUNU BAĞLAMA DÖNÜŞTÜRECEĞİM EMME NASIL?")
            const yanit = await fetch(`/signinapi`, {
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": i18n.language
                },
                // body: data
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                // console.log("yanit: ", yanit)
                    if(yanit.status === 401){
                        toast.error(t("flashMessages:UserOrPasswordIsWrongFS"))
                    }
                    else if(yanit.status === 500){
                        toast.error(t("flashMessages:server500ErrorFS"))
                    }
                // throw Error("Api call failed")
            }
            const veri = await yanit.json()
            if (veri.sonuc === "Giris Basarili"){
                toast
                .success(t("flashMessages:SuccessFullyLoggedInFS"))
            }
            // console.log("fetch veri: ", veri)
                // const v1 = EMAIL_REGEX.test(email);
                // const v2 = SIFRE_REGEX.test(sifre);
                // if (!v1|| !v2) {
                //     setHataMesaji(t('genericStrings:invalidDataS'));
                //     return;
                // }
        } catch (err) {
            console.log("catch err: ", err)
            if (err.message.includes('NetworkError')){
                toast.error(t("flashMessages:networkErrorS"))
                // setHataMesaji(t("flashMessages:networkErrorS"))
            }
        }
    }
    const onError = (errors) => {
        // setHataMesaji(`Hata on Errordan: ${errors}`)
        console.log("errors: ", errors)
    }

    return (
        <section className='centerCls'>
        <h1>{t('forms:loginFormFieldS')}</h1>
        <form 
        className='formCls' 
        onSubmit={handleSubmit(submitSignIn, onError)}
        noValidate
        >
        {/* {hataMesaji && <div>{t("flashMessages:errorS")}{ hataMesaji}</div>} */}
            <div className="formControlCls">
                <label htmlFor='email'>{t('forms:useremailFormFieldS')}</label>
                <input 
                    type='email'
                    name='email'
                    id='email'
                    // ref={emailRef}
                    {...register(
                        "email", {
                            required: {value: true, 
                            message: t('messages:thisFieldIsRequiredS')},
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t('messages:enterAValidEmailS')
                                }
                        }
                    )}
                />
                {errors.email && <div className='redSpanCls'>{t('messages:enterAValidEmailS')} </div>}
            </div>
            <div className="formControlCls">
            <label htmlFor='sifre'>
            {t('forms:userpasswordFormFieldS')} 
                    </label>
                    <input
                        type='password'
                        id='sifre'
                        // onChange={(e) => setSifre(e.target.value)}
                        required
                        aria-invalid={true}
                        aria-describedby='sifrenote'
                        // onFocus={() => setSifreOdaklan(true)}
                        // onBlur={() => setSifreOdaklan(false)}
                        {...register(
                            "sifre", {
                            required: {value: true, 
                            message: t('messages:enterAPasswordS')},
                            pattern: {
                                value: SIFRE_REGEX,
                                message: aciklamaMesaji
                            }
                        }
                        )}
                    />
                {errors.sifre && <div className='redSpanCls'>{aciklamaMesaji} <AnimasyonluKarakterler /></div>}
                <div id='sifrenote' className='invisibleCls'>{aciklamaMesaji} <AnimasyonluKarakterler/></div>
            </div>
            <div className='formControlCls'>
                    <button className='greenBackgroundCls' 
                    // disabled={!gecerliEposta || !gecerliSifre || !sifrelerUyuyor ? true : false}
                    >{t('forms:loginFormFieldS')}</button>
            </div>
        </form>
        </section>
    )
}

export default Signin;