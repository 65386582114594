/* eslint-disable no-undef */
import { configureStore } from '@reduxjs/toolkit'

import videoOdasiDilimiReducer from '../VideoOdasi/videoOdasiDilimi';
// import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici';
// const { demoState } = useGenelAyarlarBaglami();

const depo = configureStore({
    reducer: {
        videoOdasi: videoOdasiDilimiReducer,
    }
    // if(!demoState){
        // + window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // }
});

export default depo;
