import { createSlice } from "@reduxjs/toolkit";

const baslangicDurumu = {
    odadakiCevrimIciKullanicilar: [],
};


export const videoOdasiDilimi = createSlice({
    name: "videoodasi",
    initialState: baslangicDurumu,
    reducers: 
    {
        addUserToVideoRoom: (state, action) => {
        state.cevrimIciKullanicilar.push(action.payload)
        console.log("action.payload ", action.payload)
        },
    },
});


export const { addUserToVideoRoom } = videoOdasiDilimi.actions;

export default videoOdasiDilimi.reducer;
