import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CookiesProvider } from "react-cookie";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom"

import './i18n'

import './Stylelar/main.css'
import { GenelAyarlarSaglayici } from "./baglam/GenelAyarlarSaglayici";
import { KimlikDogrulamaSaglayici } from "./baglam/KimlikDogrulamaSaglayici";
import Signin from "./Bilesenler/SignIn";
import SignUp from "./Bilesenler/SignUp";
import NotFound from "./Pages/NotFound";
import VideoOdasi from "./Pages/VideoOdasi"
import KokYerlesim from "./yerlesimler/KokYerlesim";
import AccordionComp from "./Bilesenler/AccordionComp";
import { UseStateSaglayici } from "./baglam/UseStateSaglayici";
import i18n from "./i18n";
import { initializeTagManager } from "./gtm";

export const lang = i18n.language
console.log("lang: ", lang)

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={"/"} exact element={<KokYerlesim/>}>
      <Route index element={<AccordionComp />} />
      <Route path="videoodasi/:uuid" element={<VideoOdasi />} />
      <Route path="videoodasi/*" element={<VideoOdasi />} />
      <Route path="signin" element={<Signin />} />
      <Route path="signup/" element={<SignUp />} />
      <Route path="signup/:restOfUrl/*" element={<SignUp />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  ),
  {
    // basename: "/:"+lang+"/"
  }
)
// .basename = "/:"+lang
;

const App = () => {
  const { t, ready } = useTranslation();
  const [documentTitle, setDocumentTitle] = useState(`Çeviri Hizmetleri Merkezi - ${t('genericStrings:web_site_name')}`)
  // const favIcon = 'https://stackoverflow.com/favicon.ico'
  // const favIcon = '/img/tercumeederizfavicon.ico'
  const favIcon = '/img/wetranslatefavicon.ico'

  useLayoutEffect( () => {
    initializeTagManager()
  }, [])

  useLayoutEffect( () => {
    document.title = documentTitle;
    const favIconLink = document.querySelector('link[rel="icon"]');
    favIconLink.setAttribute("href", favIcon)
    // console.log("favIcon: ", favIcon, " favIconLink: ", favIconLink)
  }, [documentTitle])

  // console.log('navigator.userActivation: ', navigator.userAgent)

  !ready && console.log('loading translations <Suspense> kullanmasını öğren')
  
  return (
    <CookiesProvider defaultSetOptions={{path:'/'}}>
    <KimlikDogrulamaSaglayici>
        <UseStateSaglayici>
          <GenelAyarlarSaglayici>
            <div>
            <RouterProvider router={router}/>
            </div>
          </GenelAyarlarSaglayici>
        </UseStateSaglayici>
      </KimlikDogrulamaSaglayici>
    </CookiesProvider>
  );
};

// cd istemci npm start

export default App;
