import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form' 
import { NavLink, useParams } from 'react-router-dom'
import { useKayit } from "../kancalar/kayitKullan";


import './signup.css'
import { EMAIL_REGEX, SIFRE_REGEX } from '../yararli/utils'
import AnimasyonluKarakterler from './AnimasyonluKarakterler'
// import { UseStateSaglayici, useSignInSignUpBaglami} from "../baglam/UseStateSaglayici";

function SignUp() { 
    const { t } = useTranslation();

    const { restOfUrl } = useParams()

    const { kayitOl, yukluyor, sifreSifirla } = useKayit()

    const {
        register, 
        handleSubmit, 
        watch,
        // setError, 
        formState: 
        {errors, isSubmitting} 
    } = useForm({
        reValidateMode: 'onChange'
    })
    // const sifre = watch("sifre", "")

    /*const sifreRef = useRef()
    const teyitSifresiRef = useRef()
    const [ sifrelerBirbirineUyuyor, setSifrelerBirbirineUyuyor] = useState()*/

    const sifreAciklamaMesaji = t('messages:passwordMinLengthS:line1')+' - '+t('messages:passwordMinLengthS:line2') +' - '+t('messages:passwordMinLengthS:line3')
    const teyitSifreAciklamaMesaji = t('flashMessages:PasswordsMustMatchFS')
    // console.log("sifreAciklamaMesaji: ", sifreAciklamaMesaji)
    const submitSignUp = async (data) => {
        await kayitOl(data)
        /*try {
            // const submitSignUp = (e) => {
                // data.preventDefault();
            // console.log('mesaj: ', t('messages:thisFieldIsRequiredS'))
            /*console.log("sifreRef.current: ", sifreRef.current, " teyitSifresiRef.current: ", teyitSifresiRef.current)
            console.log('data: ', data)
            console.log('data.sifre: ', data.sifre)
            console.log('data.teyitSifresi: ', data.teyitSifresi)
            if(data.sifre !== data.teyitSifresi) {
                // console.log("Bu niye çalışmıyor?")
                toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                return 
                // setSifrelerBirbirineUyuyor(false)
            }
            const yanit = await fetch(`/signupapi`, {
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": i18n.language
                },
                // body: data
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                // console.log("yanit: ", yanit)
                    if(yanit.status === 401){
                        toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                    }
                    if(yanit.status === 409){
                        toast.error(t("flashMessages:thisEmailInUseFS"))
                    }
                    else if(yanit.status === 500){
                        toast.error(t("flashMessages:server500ErrorFS"))
                    }
                // throw Error("Api call failed")
            }
            // console.log("yanit.headers.authorization: ", yanit.headers.get('authorization').split(" ")[1])
            const isaret = yanit.headers.get('authorization').split(" ")[1]
            let veri = await yanit.json()
            veri = {...veri , isaret}
            localStorage.setItem('kullanici', JSON.stringify(veri))
            if (veri.sonuc === "Kayit Basarili"){
                // localStorage.setItem('kullanici', ...veri, isaret)
                toast
                .success(t("flashMessages:SuccessFullyLoggedInFS"))
            } 
        } catch (err) {
            console.log(err)
        }*/
    }

    const onError = (errors) => {
        console.log("errors: ", errors)
    }
    
    /*useEffect(() => {
        console.log("sifreRef.current: ", sifreRef.current, " teyitSifresiRef.current: ", teyitSifresiRef.current)
        if (sifreRef.current === teyitSifresiRef.current){
            setSifrelerBirbirineUyuyor(true)
        }
        else {
            setSifrelerBirbirineUyuyor(false)
        }
    // })
    }, [teyitSifresiRef, sifreRef])*/
    
    return (
        <section className='centerCls'>
            <h1>{t('forms:registerFormFieldS')}</h1>
            <form 
            className='formCls' 
            onSubmit={handleSubmit(submitSignUp, onError)}
            noValidate
            >
            <div className='formControlCls'>
                <label htmlFor='email'>{t('forms:useremailFormFieldS')} 
                </label>
                <input
                    type='text'
                    id='email'
                    name='email'
                    // {...register("email")}
                    {...register(
                        "email", {
                            required: {value: true, 
                            message: t('messages:thisFieldIsRequiredS')},
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t('messages:enterAValidEmailS')
                                }
                        }
                    )}
                />
                {errors.email && <div className='redSpanCls'>{errors.email?.message} </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='sifre'>{t('forms:userpasswordFormFieldS')} 
                </label>
                <input
                    type='password'
                    id='sifre'
                    name='sifre'
                    // ref={sifreRef}
                    aria-describedby='sifrenote'
                    // {...register('sifre')}
                    {...register(
                            "sifre", {
                            required: {value: true, 
                            message: t('messages:enterAPasswordS')},
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            }
                        }
                    )}
                />
                {errors.sifre && <div className='redSpanCls'>{sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <div className='formControlCls'>
                <label htmlFor='teyitSifresi'>{t('forms:userpasswordForConfirmationFormFieldS')}
                </label>
                <input
                    type='password'
                    id='teyitSifresi'
                    name='teyitSifresi'
                    // ref={teyitSifresiRef}
                    aria-describedby='teyitSifrenote'
                    // {...register('teyitSifresi')}
                    {...register(
                            "teyitSifresi", {
                            required: {value: true, 
                            message: t('messages:enterAPasswordS')},
                            pattern: {
                                value: SIFRE_REGEX,
                                message: sifreAciklamaMesaji
                            },
                            // validate: value => value === sifreRef.current || alert("Hoba")
                        }
                    )}
                />
                {errors.teyitSifresi && <div className='redSpanCls'>{ teyitSifreAciklamaMesaji } {sifreAciklamaMesaji} <AnimasyonluKarakterler/> </div>}
            </div>
            <input
                type='hidden'
                id='urltoUse'
                {...register("urltoUse")}
                value={restOfUrl}
            />
            <div className='formControlCls'>
                <button className='greenBackgroundCls' 
                disabled={yukluyor}
                >{yukluyor ? t('forms:waitFormS') : t('forms:registerFormFieldS')}</button>
                {/* disabled={isSubmitting}
                >{isSubmitting ? t('forms:waitFormS') : t('forms:registerFormFieldS')}</button> */}
            </div>
            </form>
            <p className={sifreSifirla ? '' : 'hideItCls'}><NavLink className='redspanCls' to='../sifreSifirla'>{t('forms:forgotpasswordAndWantToResetS')} </NavLink></p>
        </section>
    )
}

export default SignUp