import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";


const EMAIL_REGEX= /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// const EMAIL_REGEX= /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eski hali
const SIFRE_REGEX= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const SignInSignUpBaglami = createContext({});

export const useSignInSignUpBaglami = () => {
    const signInSignUpBaglami = useContext(SignInSignUpBaglami)
    
    if(signInSignUpBaglami === undefined) {
        throw new Error("SignInSignUpBaglami is used outside the provider")
    }
    return signInSignUpBaglami
}

export const UseStateSaglayici = ({children}) => {
    const { t } = useTranslation();
    const [passWordSpanState, setPassWordSpanState] = 
    useState(t('messages:passwordMinLengthS.info', { returnObjects: true}) || [])
    const emailRef = useRef();
    const hataMesajiRef = useRef();
    
    const [email, setEmail] = useState('');
    const [gecerliEposta, setGecerliEposta] = useState(false);
    const [emailOdaklan, setEmailOdaklan] = useState(false);
    
    const [sifre, setSifre] = useState('');
    const [gecerliSifre, setGecerliSifre] = useState(false);
    const [sifreOdaklan, setSifreOdaklan] = useState(false);
    
    const [teyitSifresi, setTeyitSifresi] = useState('');
    const [sifrelerUyuyor, setSifrelerUyuyor] = useState(false);
    const [uyanSifreOdaklan, setUyanSifreOdaklan] = useState(false);
    
    const [hataMesaji, setHataMesaji] = useState('');
    
    // useEffect(() => {
    //     emailRef.current.focus();
    // }, []);

    /*useEffect(() => {
        setPassWordSpanState(t('messages:passwordMinLengthS.info', { returnObjects: true}) || []);
    }, [t, passWordSpanState]);
    
    useEffect(() => {
        const sonuc = EMAIL_REGEX.test(email);
        // console.log('email: ', email);
        // console.log('sonuc: ', sonuc);
        setGecerliEposta(sonuc);
    }, [email]);
    
    useEffect(() => {
        if (sifre ==="" && teyitSifresi === "") {
            // setSifrelerUyuyor()
            return setGecerliSifre(false)
        }
        const sonuc = SIFRE_REGEX.test(sifre);
        // console.log('sifre: ', sifre);
        // console.log('sonuc: ', sonuc);
        setGecerliSifre(sonuc);
        const uyuyormu = sifre === teyitSifresi;
        // console.log('uyuyormu: ', uyuyormu);
        // console.log('gecerliSifre: ', gecerliSifre);
        console.log('sifrelerUyuyor: ', sifrelerUyuyor);
        console.log('teyitSifresi: ', teyitSifresi);
        setSifrelerUyuyor(uyuyormu);
        // console.log('sifrelerUyuyor: BURADA NEDEN TERSİ OLUYOR uyuyor mu true ise BU DA TRUE OLMALI', sifrelerUyuyor);
    }, [sifre, teyitSifresi, sifrelerUyuyor]);
    
    useEffect(() => {
        setHataMesaji('')
    }, [email, sifre, sifrelerUyuyor]); */

    return (
        <SignInSignUpBaglami.Provider value={
            {
                email,
                setEmail,
                emailRef,
                hataMesajiRef,
                gecerliEposta,
                setGecerliEposta,
                emailOdaklan,
                setEmailOdaklan,
                sifre,
                setSifre,
                gecerliSifre,
                setGecerliSifre,
                sifreOdaklan,
                setSifreOdaklan,
                teyitSifresi,
                setTeyitSifresi,
                sifrelerUyuyor,
                setSifrelerUyuyor,
                uyanSifreOdaklan,
                setUyanSifreOdaklan,
                hataMesaji,
                setHataMesaji,
            }
        }>
            { children }
        </SignInSignUpBaglami.Provider>
    )
}

export default SignInSignUpBaglami;
