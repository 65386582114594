import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useKimlikDogrulamaBaglami } from "../baglam/KimlikDogrulamaSaglayici";

export const useKayit = () => {
    const { t, i18n } = useTranslation();
    const [ sifreSifirla, setSifreSifirla] = useState(false)
    const [ yukluyor, setYukluyor] = useState(false)
    const { dispatch } = useKimlikDogrulamaBaglami()

    const kayitOl = async (data) => {
        try {
            setYukluyor(true)
            if(data.sifre !== data.teyitSifresi) {
                toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                setYukluyor(false)
                return 
            }
            const yanit = await fetch(`/signupapi`, {
                method: "POST",
                headers: {
                    "access-control-allow-origin" : "*",
                    "Accept": "application/json",
                    "Content-type": "application/json; charset=UTF-8",
                    "Accept-Language": i18n.language
                },
                body: JSON.stringify(data)
            })
            if (!yanit.ok) {
                // console.log("yanit: ", yanit)
                setYukluyor(false)
                if(yanit.status === 401){
                    toast.error(t("flashMessages:PasswordsDoNotMatchFS"))
                }
                if(yanit.status === 409){
                    toast.error(t("flashMessages:thisEmailInUseFS"))
                    setSifreSifirla(true)
                }
                else if(yanit.status === 500){
                    toast.error(t("flashMessages:server500ErrorFS"))
                }
            }
            const isaret = yanit.headers.get('authorization')?.split(" ")[1] || ''
            let veri = await yanit.json()
            veri = {...veri , isaret }
            console.log("veri: ", veri)
            if (veri.sonuc === "Kayit Basarili"){
                //yere depoya kaydet
                localStorage.setItem('kullanici', JSON.stringify(veri))
                // bağlama ekle
                dispatch({type: 'SIGNUP', payload: veri})
                toast
                .success(t("flashMessages:SuccessFullyLoggedInFS"))
                setYukluyor(false)
            }
        } catch (err) {
            console.log(err)
            setYukluyor(false)
        }
    }

    return { kayitOl, yukluyor, sifreSifirla }
}