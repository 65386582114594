import { useState, useEffect } from 'react';

function pencereOlculeriniAl() {
    const { innerWidth: genislik, innerHeight: yukseklik } = window;
    return {
        genislik,
        yukseklik
    };
}

export default function useWindowDimensions() {
    const [pencereOlculeri, setPencereOlculeri] = useState(pencereOlculeriniAl());

    useEffect(() => {
        function handleResize() {
        setPencereOlculeri(pencereOlculeriniAl());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return pencereOlculeri;
}