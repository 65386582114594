import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import './../i18n'

import './navbar.css'
import useWindowDimensions from '../kancalar/pencereBuyuklugunuKullan';

const throbVariant = {
    inital: {
        x: 5
    },
    throb: {
        scale:[1, 1.1],
        transition:{repeat: Infinity, duration:1}
    }
}

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const { genislik } = useWindowDimensions();
    const AnimatedLink = motion(NavLink)

    const navCompRef = useRef();
    const aboutUsRef = useRef();
    const navCenterRef = useRef();
    const navLoginRef = useRef();
    const navLanguageRef = useRef();
    const navLoginDropDownRef = useRef();
    const loginDropDownUlRef = useRef();
    const languageDropDownUlRef = useRef();
    // const languageAlignRightUlRef = useRef();
    const navButtonRef = useRef();

    const changeLanguageNow = (lang) => {
        i18n.changeLanguage(lang)
    }

    const showLoginDropDown = () => {
        languageDropDownUlRef.current.className = ""
        loginDropDownUlRef.current.classList.toggle("showInlineBlock")
        // loginDropDownUlRef.current.children[0].classList.toggle("showInlineBlock")
        // loginDropDownUlRef.current.children[1].classList.toggle("showInlineBlock")
    }
    
    const showLanguageDropDown = () => {
        loginDropDownUlRef.current.className = ""
        languageDropDownUlRef.current.classList.toggle("showInlineBlock")
        // languageDropDownUlRef.current.children[0].classList.toggle("showInlineBlock")
        // languageDropDownUlRef.current.children[1].classList.toggle("showInlineBlock")
    }

    const mobilMenuyuGosterGizle = () => {
        navCompRef.current.classList.toggle("navOpened")
        navCenterRef.current.classList.toggle("visibleCls")
        navCenterRef.current.classList.toggle("invisibleCls")
        navLoginRef.current.classList.toggle("visibleCls")
        navLoginRef.current.classList.toggle("invisibleCls")
        // navLoginDropDownRef.current.children[0].querySelector("ul").classList.toggle("visibleCls")
        // navLoginDropDownRef.current.children[0].children[0].classList.toggle("invisibleCls")
        navLanguageRef.current.classList.toggle("visibleCls")
        navLanguageRef.current.classList.toggle("invisibleCls")
    }
    return (
        <div className='headerCls'>
            <nav ref={navCompRef}>
                <div className='navLeftSideCls'>
                <AnimatedLink to={"/"} className= 'logoCls'
                    variants={throbVariant}
                    initial="initial"
                    animate="throb"
                >
                {t('genericStrings:web_site_name')}
                </AnimatedLink>
                </div>
                <div ref={navCenterRef} className={genislik <720 ? 'navAboutCls invisibleCls' : 'navAboutCls' }>
                <NavLink ref={aboutUsRef} className='aboutUs' >{t('regularMenus:aboutUsMenu')}</NavLink>
                </div>
                <div ref={navLoginRef} className={genislik <720 ? 'navLoginCls invisibleCls': 'navLoginCls'}>
                    <ul ref={navLoginDropDownRef} className='navBarDropDownCls'>
                        <li>
                            <NavLink 
                            // onTouchStart={genislik <720 && showLoginDropDown}
                            >{t('regularMenus:loginRegisterMenu')}
                            </NavLink>
                            <ul ref={loginDropDownUlRef}>
                                <li className='logInCls'>
                                <NavLink to={'signin'}>{t('regularMenus:loginMenu')}</NavLink>
                                    {/* <a href='/#'>{t('regularMenus:loginMenu')}</a> */}
                                </li>
                                <li className='logInCls'>
                                <NavLink to={'signup'}>{t('regularMenus:registerMenu')}</NavLink>
                                    {/* <a href='/#'>{t('regularMenus:registerMenu')}</a> */}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div ref={navLanguageRef} className={genislik <720 ? 'navLanguageCls invisibleCls' : 'navLanguageCls' } >
                    <ul className='navBarDropDownCls'>
                        <li>
                            <NavLink 
                            // onTouchStart={genislik < 720 && showLanguageDropDown}
                            >{t('linguisticallyReverseMenuItems:guilangSelectS')}</NavLink>
                            <ul ref={languageDropDownUlRef}>
                                <li className='logInCls'>
                                <NavLink onClick={() => {changeLanguageNow('tr')}}>{t('linguisticallyReverseMenuItems:guilangTurS')}</NavLink>
                                </li>
                                <li className='logInCls'>
                                <NavLink onClick={() => {changeLanguageNow('en')}}>{t('linguisticallyReverseMenuItems:guilangEngS')}</NavLink>
                                </li>
                            </ul>
                        </li>
                        </ul>
                </div>
                <button ref={navButtonRef} className='nav-btn'onClick={mobilMenuyuGosterGizle}>
                    <FaBars/>
                </button>
            </nav>
        </div>
    );
};

export default Navbar;