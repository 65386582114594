import React, { createContext, useContext, useState } from "react";

const GenelAyarlarBaglami = createContext({});

export const useGenelAyarlarBaglami = () => {
    const genelAyarlar = useContext(GenelAyarlarBaglami)

    if(genelAyarlar === undefined) {
        throw new Error("useGenelAyarlarBaglami is used outside the provider")
    }
    return genelAyarlar
}

const badwords =    [
    'fuck',
    'FUCK',
    'ass',
    'ASS',
    'asshole',
    'ASSHOLE',
    'nigger',
    'NİGGER',
    'NIGGER',
    'negro',
    'NEGRO',
    'shit', 
    'SHİT', 
    'SHIT', 
    'turd', 
    'TURD', 
    'dick', 
    'DİCK', 
    'DICK', 
    'fucker', 
    'FUCKER', 
    'motherfucker', 
    'MOTHERFUCKER', 
    'mother-fucker', 
    'MOTHER-FUCKER', 
    'mother fucker', 
    'MOTHER FUCKER', 
    'sik', 
    'SİK', 
    'yarak', 
    'YARAK', 
    'yarrak', 
    'YARRAK', 
    'yarram', 
    'YARRAM', 
    'yarraam', 
    'YARRAAM',
    'çük', 
    'ÇÜK', 
    'sikiş', 
    'SİKİŞ', 
    'sikik', 
    'SİKİK', 
    'siker', 
    'SİKER', 
    'sikerim', 
    'SİKERİM', 
    'sikeriz', 
    'SİKERİZ', 
    'taşak', 
    'TAŞAK', 
    'taşaklı', 
    'TAŞAKLI', 
    'taşaklar', 
    'TAŞAKLAR', 
    'am', 
    'AM', 
    'amı', 
    'AMI', 
    'amcık', 
    'AMCIK', 
    'amına', 
    'AMINA', 
    'göt', 
    'GÖT', 
    'büzük', 
    'BÜZÜK',  
    'bok',
    'BOK',
    'pkk',
    'PKK',
    'ypg',
    'YPG',
    'işid',
    'İŞİD',
    'isis',
    'ISIS',
    'daeş',
    'DAEŞ',
]

const goodWords =   [
'salam',
'salami',
'salamis',
'yaramaz',
'Yaramaz',
'YARAMAZ',
'lass',
'küçük',
'kesik',
'Kesik',
'KESİK',
'bazik',
'Bazik',
'BAZİK',
'klasik',
'Klasik',
'KLASİK',
'sikke',
'Sikke',
'SİKKE',
]

const notAppropriateUserNames = [
    'altan baba',
    'Altan Baba',
    'Altan',
    'altan',
    'Altan baba'
]

export const checkforAppropriateUserNames = (username, req) => {
    if(typeof(req.session.kullanici) != 'undefined'){
        if(req.session.kullanici.id === 1){
            return false
        }
    }
    else if (username.includes('altan baba') ||
        username.includes('Altan Baba') || 
        username.includes('Altan') || 
        username.includes('altan') || 
        username.includes('Altan baba') 
        ){
        return true
    }
    return false
}

export const kufurIceriyorMu = (girenSozcuk) => {
    var lowerCaseSozcuk = girenSozcuk
    // var lowerCaseSozcuk = girenSozcuk.toLowerCase()
    var kufurVar = false
    for(const bulunanEleman of badwords){
        if(lowerCaseSozcuk.includes(bulunanEleman)){
            kufurVar = true
        }
        if (goodWords.includes(lowerCaseSozcuk)){
            kufurVar = false
        }
    }
    return kufurVar
}

export function GenelAyarlarSaglayici({children}) {
    const [demoState, setDemoState ] = useState(true)
    const [ BASEURL, setBASEURL] = useState('http://localhost:3500')

    function putSiteInDemo() {
        setDemoState(true)
    }
    return (
        <GenelAyarlarBaglami.Provider 
        value={
            {demoState, 
            putSiteInDemo,
            BASEURL,
            setBASEURL,
            }
        }
        >
            { children }
        </GenelAyarlarBaglami.Provider>
    )
}

export default GenelAyarlarBaglami;
