const hizmetIscisiGelistirme = () => {
    // console.log("hizmetIscisiGelistirme")
    if ('serviceWorker' in navigator) {
        // console.log("hizmetIscisiGelistirme 2")
        const serviceIscisiAcik = `${process.env.PUBLIC_URL}/hizmetIscisi.js`
        // console.log("serviceIscisiAcik: ", serviceIscisiAcik)
        navigator
        .serviceWorker
        .register(serviceIscisiAcik)
        .then(registration => {
            // console.log("hizmetIscisiGelistirme 3")
        })
        .catch(err => {
            console.log("catch err: ", err)
        })
    }
}

export default hizmetIscisiGelistirme;