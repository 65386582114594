import React, { createContext, useContext, useReducer, useState } from "react";

const KimlikDogrulamaBaglami = createContext({});

export const useKimlikDogrulamaBaglami = () => {
    const kimlikDogrulamaBaglami = useContext(KimlikDogrulamaBaglami)
    if (kimlikDogrulamaBaglami === undefined) {
        throw new Error("KimlikDogrulamaBaglami is used outside the provider")
    }
    return kimlikDogrulamaBaglami
}

const initialState = {
    kullanici: null,
    kullaniciDogrulanmis: false,
    jwtErisimIsareti: null
}
export const kimlikReducer = (state, action) => {
    switch (action.type) {
        case "SIGNUP":
            return {
                ...state,
                kullanici: action.payload,
                kullaniciDogrulanmis: true
            }
        case "SIGNIN":
            return {
                ...state,
                kullanici: action.payload,
                kullaniciDogrulanmis: true
            }
        case "SIGNOUT":
            return {
                ...state,
                kullanici: null,
                kullaniciDogrulanmis: false
            }
        default:
            return state
            // throw new Error("Bilinmeyen eylem")
    }
}

export const KimlikDogrulamaSaglayici = ({ children }) => {
    // const [kimlikDogrulama, setKimlikDogrulama ] = useState({});
    const [yukluyor, setYukluyor] = useState(false)
    const [girisHatasi, setGirisHatasi] = useState(false)

    const [{
        kullanici, 
        kullaniciDogrulanmis,
        jwtErisimIsareti,
        }, 
        dispatch] = useReducer(kimlikReducer, initialState)

    const signIn = (username, password) => {
        setYukluyor(true)
        setGirisHatasi(null)
        dispatch({type: "SIGNIN"})
    }
    const signOut = () => {
        dispatch({type: "SIGNOUT"})
    }

    console.log("burada erişim işaretçisini al state : ", kullanici, " kullanici doğrulanmış : ", kullaniciDogrulanmis, " jwtErisimIsareti: ", jwtErisimIsareti)

    return (
        <KimlikDogrulamaBaglami.Provider value={
                {
                kullanici,
                kullaniciDogrulanmis,
                signIn,
                signOut,
                }
            }>
            { children }
        </KimlikDogrulamaBaglami.Provider>
    )
}

//export default KimlikDogrulamaBaglami; //bunu kullanmak zorunda mısın? Değilsen çıkart bunu