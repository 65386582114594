
export const EMAIL_REGEX= /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
// const EMAIL_REGEX= /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eski hali
export const SIFRE_REGEX= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const shakeVariant = {
    inital: {
        x: 0
    },
    shake: {
        translateX:[5, 0, 5],
        transition:{repeat: Infinity, duration:0.25}
    }
};
export const scaleVariant = {
    inital: {
        scale: 1
    },
    shake: {
        scale:[2, 1],
        transition:{repeat: Infinity, duration:0.25}
    }
};

export const rotateVariant = {
    inital: {
        x: 0
    },
    rotate: {
        rotate:[30, 0, 30],
        transition:{repeat: Infinity, duration:1}
    }
};