import React, { useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie';

import SocialMediaComponent from './SocialMediaComponent';
import TelifBildirimi from './TelifBildirimi';
import CookieConsent from './CookieConsent';
import MobileApps from './MobileApps';
import DropUpMenu from './DropUpMenu'

import './footer.css'
import { useGenelAyarlarBaglami } from '../baglam/GenelAyarlarSaglayici';

const Footer = () => {
    const [ cookies, setCookies] = useCookies(['IAcceptAllCookies']);
    // const { cookiess} = useGenelAyarlarBaglami();

    let cookiesAccepted = "";
    // console.log("Footer İlk cookiesAccepted: ", cookies.IAcceptAllCookies)
    // const cookiePromptRef = useRef();


    // var classToBeAddedToDiv = "";

    useEffect(() => {
        return () => {
            cookiesAccepted = cookies.IAcceptAllCookies;
            // cookiePromptRef.current.className = "";
            if(cookiesAccepted==='yesIAcceptAllCookies'){
            }
            else {
            }
            // console.log("footer cookiesAccepted changed: cookiesAccepted", cookiesAccepted)
        }
    }, [cookiesAccepted]);
    
    // useEffect(() => {
    //     return () => {
    //         cookiesAccepted = iAcceptAllCookies.IAcceptAllCookies;
    //         console.log("Footer comp Mounted: cookiesAccepted", cookiesAccepted)
    //         if(cookiesAccepted === '' || cookiesAccepted === undefined){
    //             setIAcceptAllCookies("IAcceptAllCookies", "", {
    //                 path: "/",
    //                 sameSite: true,
    //                 hostOnly: false,
    //             })
    //         }
    //     }
    // });

    return (
        <footer>
            {(cookies.IAcceptAllCookies !== "yesIAcceptAllCookies") && <CookieConsent />}
            <div className='footerDiv'>
                <div className='footerItems'>
                    <TelifBildirimi/>
                </div>
                <div className='footerItems'>
                    <MobileApps />
                </div>
            <DropUpMenu/>
            </div>
        </footer>
    )
}

export default Footer