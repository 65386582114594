import React from 'react'

import './EkmekKirintilari.css'

const EkmekKirintilari = () =>{
    return (
        <div className='kirintilarCls'>EkmekKirintilari</div>
    )
}

export default EkmekKirintilari