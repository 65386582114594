import React, { useEffect } from 'react'
import { useKimlikDogrulamaBaglami } from '../baglam/KimlikDogrulamaSaglayici'
import { useNavigate } from 'react-router-dom'

export default function VideoOdasi() {
    const { kullaniciDogrulanmis } = useKimlikDogrulamaBaglami()
    const x = useKimlikDogrulamaBaglami()
    console.log("x: ", x)
    const navigate = useNavigate()
    useEffect(() => {
        if (!kullaniciDogrulanmis) {navigate("/signin")}
    }, [kullaniciDogrulanmis, navigate])

    return kullaniciDogrulanmis ? (
        <div>
        VideoOdasi
        </div>
    ) : null
}
